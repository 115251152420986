
import { defineComponent, onMounted, onUnmounted, watch, reactive } from 'vue'
import ButtonIcon from '@/components/molecules/ButtonIcon.vue'
import fixBackground from '@/helpers/util/fixBackground'
import { focusInsideDialog } from '@/helpers/util/focusInsideDialog'
import md from '@/helpers/util/MobileDetect'

export default defineComponent({
  components: {
    ButtonIcon,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close'],
  setup (props, context) {
    const isServer = typeof window === 'undefined'
    const breakpoint = md.width()

    const escape = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        context.emit('close')
      }
    }

    // TODO refactor ページネーション対応時にsearchResultState.tsへ色々移管する
    const state = reactive({
      showModal: false,
    })

    const closeModal = () => {
      context.emit('close')
      state.showModal = false
      if (!isServer) {
        const focusElement = document.getElementById('sorting-button')
        if (focusElement) {
          focusElement.focus()
        }
      }
    }

    watch(
      () => props.show,
      (value) => {
        if (value) {
          fixBackground.fix('unsafety', `#${props.id} .modal-sheet-mobile-body`)
          window.addEventListener('keydown', escape)
        } else {
          fixBackground.cancel()
          window.removeEventListener('keydown', escape)
        }
      }
    )

    onUnmounted(() => {
      if (props.show) {
        fixBackground.cancel()
      }
      if (!isServer) {
        window.removeEventListener('keydown', escape)
      }
    })

    return {
      breakpoint,
      closeModal,
    }
  },
})
