
import { defineComponent } from 'vue'

import CollectionDetail from '@/components/organisms/CollectionDetail.vue'
import { useRoute } from 'vue-router'

export default defineComponent({
  name: 'DetailSearchFormPage',
  components: {
    CollectionDetail,
  },
  setup () {
    const route = useRoute()
    const selectedCollectionId = route.params.collectionId

    return {
      selectedCollectionId,
    }
  },
})
