import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, withKeys as _withKeys, createVNode as _createVNode, renderSlot as _renderSlot, vShow as _vShow, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d5eb9ed8"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id", "aria-label"]
const _hoisted_2 = { class: "level is-mobile is-hidden-desktop" }
const _hoisted_3 = { class: "has-text-contents-title has-text-modest" }
const _hoisted_4 = { class: "modal-sheet-mobile-body" }
const _hoisted_5 = { class: "modal-sheet-mobile-contents" }
const _hoisted_6 = { class: "is-hidden-desktop" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ButtonIcon = _resolveComponent("ButtonIcon")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_Transition, { name: "modal" }, {
      default: _withCtx(() => [
        _withDirectives(_createElementVNode("section", {
          id: _ctx.id,
          class: "modal-sheet-mobile",
          role: "dialog",
          "aria-modal": "true",
          "aria-labelledby": "modal-sheet-heading",
          "aria-label": _ctx.$t('label.dialog')
        }, [
          _createElementVNode("header", _hoisted_2, [
            _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.title), 1),
            _createVNode(_component_ButtonIcon, {
              onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.closeModal()), ["stop"])),
              onKeydown: _cache[1] || (_cache[1] = _withKeys(_withModifiers(($event: any) => (_ctx.closeModal()), ["prevent","stop"]), ["space","enter"])),
              styled: "is-none",
              icon: "xmark-circle",
              "aria-label": _ctx.$t('parts.close')
            }, null, 8, ["aria-label"])
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _renderSlot(_ctx.$slots, "default", {}, undefined, true),
              _renderSlot(_ctx.$slots, "modal", {}, undefined, true)
            ])
          ])
        ], 8, _hoisted_1), [
          [_vShow, _ctx.show || !_ctx.breakpoint]
        ])
      ]),
      _: 3
    }),
    _createElementVNode("div", _hoisted_6, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ])
  ], 64))
}